
export default {
  name: 'InfiniteScroll1',
  props: {
    images: Array,
    parentContainer: Object,
    container: Object,
    scrollSettings: Object,
  },
  data() {
    return {
      defaultValues: {
        gap: 20,
        speed: 25,
        logoWidth: 250,
      },
    };
  },
  computed: {
    styleDataSet() {
      const gap = this.scrollSettings.gap ? this.scrollSettings.gap : this.defaultValues.gap;
      const speed = this.scrollSettings.speed ? this.scrollSettings.speed : this.defaultValues.speed;
      const logoWidth = this.scrollSettings.logoWidth ? this.scrollSettings.logoWidth : this.defaultValues.logoWidth;
      const gapForItems = this.images.length * gap;
      const animationWidth = this.images.length * logoWidth + gapForItems;
      return {
        '--scroll-gap': `${gap}px`,
        '--scroll-speed': `${speed}s`,
        '--scroll-logo-width': `${logoWidth}px`,
        '--scroll-width-dataset0-start': '0',
        '--scroll-width-dataset0-end': `-${animationWidth}px`,
        '--scroll-width-dataset1-start': `${animationWidth}px`,
        '--scroll-width-dataset1-end': '0',
        '--scroll-width-dataset2-start': `${animationWidth * 2}px`,
        '--scroll-width-dataset2-end': `${animationWidth}px`,
      };
    },
  },
};
